(function ($) {
  'use strict';

  $(document).on('ready turbolinks:load page:load module:load paginate', function() {
    $('[data-widget="ajax-modal"]').each(function () {
      var element = $(this);
      if (element.data('AJAX_MODAL')) {
        return;
      }
      element.data('AJAX_MODAL', true);
      var modal = $('<div class="modal fade" role="dialog" tabindex="-1"></div>').appendTo('body');
      if (element.attr('data-modal-id') && element.attr('data-modal-id') !== "") {
        modal.attr('id', element.attr('data-modal-id'));
      }
      var request;
      element.click(function (e) {
        var url = element.attr('href');
        e.preventDefault();
        e.stopPropagation();
        if (request) {
          request.abort();
        }
        modal.html('');
        request = $.ajax({
          url: url
        }).done(function (data) {
          modal.html(data);
          modal.modal('show');
          $(document).trigger('turbolinks:load');
        }).always(function () {
          request = null;
        });
      });
    });
  });
})(jQuery);
