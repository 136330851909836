// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
const Trix = require("trix")
require('@rails/actiontext');
require("channels")
require('bootstrap');
window.moment = require('moment');
window.$ = window.jQuery = require('jquery');
require('./libs/turboboost');
require('./libs/bootstrap-datetimepicker.min');
require('./libs/bootstrap-tagsinput');
require('./libs/flash-messenger.min');
require('./libs/flash-messenger-ext');
require('./libs/flash-messenger-bootstrap');
require('./libs/flash-messenger-turboboost');
require('./libs/usertime');
require('./libs/colors');
require('./libs/jqColorPicker.min');
require('./libs/kolor-picker.min');
require('./widgets/ajax-modal');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

function closeModal(redirectUrl) {
  Turbolinks.visit(redirectUrl || location.toString());
}
window.closeModal = closeModal;

$(document).on('turbolinks:load', function() {
  var pathname = location.pathname;
  $('[data-pathname="' + pathname + '"]').addClass('active');
  if (!$('[data-pathname="' + pathname + '"]').length) {
    pathname = location.pathname.split('/').slice(0, 3).join('/');
    $('[data-pathname="' + pathname + '"]').addClass('active');
  }

  $('[data-pathname].active').parents('.submenu').addClass('show');

  $('[data-toggle="datetimepicker"]').each(function () {
    $(this).datetimepicker({
      format: 'YYYY-MM-DDTHH:mm:ssZZ'
    });
  });

  $('[data-toggle="tagsinput"]').each(function () {
    $(this).tagsinput();
  });

  $('[data-ride="carousel"]').each(function () {
    $(this).carousel();
  });

  $('.review').click(function () {
    var url = $(this).find('img').attr('src');
    $('#image').html('<img src="' + url + '" / >');
    $('#modal').modal();
  });
});

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle'
  }
});

var getHexColor, getRGBValues, numberToHex;

getHexColor = function(color) {
  var hexValues, rgbValues;
  if (!color) {
    return "";
  }
  if (/^#/.test(color)) {
    return color;
  }
  rgbValues = getRGBValues(color);
  hexValues = rgbValues.map(numberToHex);
  return "#" + hexValues.join("");
};

numberToHex = function(number) {
  return ("0" + (number.toString(16))).slice(-2).toUpperCase();
};

getRGBValues = function(color) {
  var b, canvas, context, data, g, match, r, _i;
  if (match = color.match(/rgba?\((\d+),\s*(\d+),\s*(\d+)/i)) {
    _i = match.length - 3, r = match[_i++], g = match[_i++], b = match[_i++];
  } else {
    canvas = document.createElement("canvas");
    canvas.height = canvas.width = 1;
    context = canvas.getContext("2d");
    context.fillStyle = color;
    context.fillRect(0, 0, 1, 1);
    data = context.getImageData(0, 0, 1, 1).data;
    r = data[0], g = data[1], b = data[2];
  }
  return [r, g, b].map(Number);
};

Trix.config.textAttributes.color = {
  styleProperty: 'color',
  parser: function(element) {
    var color, styleColor;
      while (element && element.tagName !== 'TRIX-EDITOR') {
        if (styleColor = element.style[this.styleProperty]) {
          if (color = getHexColor(styleColor)) {
            return color;
          }
        }
        element = element.parentElement;
      }
  },
  inheritable: true
}

Trix.config.blockAttributes.heading2 = {
  tagName: 'h2'
};

Trix.config.blockAttributes.heading3 = {
  tagName: 'h3'
};

addEventListener('trix-initialize', function(event) {
  event.target.toolbarElement.querySelector('.trix-button-group--text-tools')
    .insertAdjacentHTML('beforeEnd', '<div class="color-wrapper"><div class="color" style="background-color:white">T</div></div>');

  event.target.toolbarElement.querySelector('.trix-button-group--block-tools')
    .insertAdjacentHTML('afterBegin', '<button type="button" class="trix-button" data-trix-attribute="heading3" title="H3" tabindex="-1">H3</button>');

  event.target.toolbarElement.querySelector('.trix-button-group--block-tools')
    .insertAdjacentHTML('afterBegin', '<button type="button" class="trix-button" data-trix-attribute="heading2" title="H2" tabindex="-1">H2</button>');

  var element = document.querySelector('trix-editor');
  $('.trix-button-group--text-tools .color-wrapper .color').kolorPicker({
    doRender: false,
    onSelect: function (color) {
      $(this).css('background-color', color);
      element.editor.activateAttribute('color', color);
    }
  });
});
