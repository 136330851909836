(function ($) {
  'use strict';

  var typeMap = {
    error: 'danger',
    notice: 'info',
    alert: 'warning'
  };
  flash.setting.message = '<div class="alert alert-{type}">{message}<span class="close">&times;</span></div>';
  flash.setting.closeHandler = 'span';
  flash.setting.convert = function (options) {
    options.type = typeMap[options.type] || options.type;
  };
})(jQuery);
